<template>
  <div class="position-relative">
    <header class="header bg-light">
      <NavBar />
    </header>
    <transition>
      <main class="main">
          <router-view /> 
      </main>
    </transition>
  </div>
</template>
<script>
import NavBar from "@/components/navBar.vue";
// import 
export default {
  components: {
    NavBar,
  },
};
</script>>
<style lang="less">
.header {
  height: 90px;
}
.main {
  overflow-x: hidden;
  background-color: #11163c;
}
</style>
