<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top mb-3 dy-nav">
    <router-link :to="{ name: 'index' }">
      <span class="color-default d-flex align-items-center">
        <img class="w-75" src="@/static/image/logo-2.png" alt />
        <img style="margin-left: 20px" class="w-75" src="@/static/image/honor_flag.png" alt />
        <!-- <div class="px-1 text-white logo-title">
          <h5 class="mb-0">中壬银兴</h5>
          <small>智能风控构筑信贷服务生态，让企业信贷更容易！</small>
        </div> -->
      </span>
    </router-link>
    <div class="collapse navbar-collapse" id="navbarsExample09">
      <ul class="navbar-nav mr-auto d-flex flex-fill text-white ml-5">
        <li class="nav-item" >
          <router-link class="dy-nav-link" :to="{ name: 'index' }"
            >首页</router-link
          >
        </li>
        <li class="nav-item nav-item-two" >
          <span :class="routerPro ? 'dy-product' : ''" class="dy-nav-link"
            >产品服务<span class="caret"></span>
          </span>
          <ul
            :class="showList ? 'product-list-block-1' : ''"
            class="product-list product-list-block"
            @click="listCancel"
            ref="listDisplay"
          >
            <li class="product-list-item" >
              <router-link :to="{ name: 'video' }" @click="cancel">
                产品视频介绍
              </router-link>
            </li>
            <li class="product-list-item">
              <router-link :to="{ name: 'system' }">PVE系统</router-link>
            </li>
            <li class="product-list-item">
              <router-link :to="{ name: 'smart' }">投融资智能匹配</router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item" >
          <router-link class="dy-nav-link" :to="{ name: 'solution' }"
            >解决方案</router-link
          >
        </li>
        <li class="nav-item" >
          <router-link class="dy-nav-link" :to="{ name: 'about' }"
            >关于中壬银兴</router-link
          >
        </li>
      </ul>
    </div>
    <a
      href="https://www.cnskbb.com/"
      target=" _blank"
      class="dy-btn border-0 rounded-pill border-dark small text-white bg-pink px-4 py-2"
    >
      免费试用
    </a>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      showList: false,
      routerPro: false,
    };
  },

  watch: {
    $route(to, from) {
      if (to.name == "video" || to.name == "smart" || to.name == "system") {
        this.routerPro = true;
        // console.log(this.)
      } else {
        this.routerPro = false;
      }
      // console.log("路由的变化来自", from);

      // console.log("路由的变化去", to);
    },
  },

  methods: {
    listCancel() {
      this.showList = true;
    },
    cancel() {
      console.log("子元素點擊");
    },
  },
};
</script>>
<style lang="less" scoped>
// 产品服务高亮
.dy-product {
  color: #ff4484 !important;
}
// none:{}
// 产品服务
.product-list-block-1 {
  display: none;
}
.product-list-block {
  display: none;
}
.product-list {
  position: absolute;
  z-index: 10;
  left: -50%;
  width: 125px;
  text-align: left;
  font-size: 14px;
  margin: 6px;
  background-color: rgba(0, 0, 0, 0.4);
  // opacity: 0;
  transition: 0.5s display;
  .product-list-item {
    width: 100%;
    a {
      width: 100%;
      height: 100%;
      display: block;
    }
    &:hover {
      background-color: rgba(217, 30, 106, 0.8);
      a {
        color: white !important;
      }
    }
    list-style-type: none;
    height: 40px;
    padding-left: 15px;
    line-height: 40px;
  }
}

.dy-nav {
  padding: 0 10rem !important;
  height: 90px;
  background: #11163c;
}
.nav-item {
  text-align: left;
  margin-left: 40px;
  &:nth-child(2) {
    position: relative;
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }

    &:hover .product-list-block {
      display: block;
      // opacity: 1;
    }
  }
}
.nav-item-two {
  position: relative;
  &:after {
    position: absolute;
    content: "111";
    left: -50%;
    width: 125px;
    height: 120px;
    background-color: transparent;
    font-size: 0;
  }
}
.logo-title {
  color: #fff;
  transition: color 0.5s linear;
}
.logo-title:hover {
  color: #ff4484 !important;
}
.nav-item .dy-nav-link {
  color: white;
  position: relative;
  display: block;
  padding-bottom: 6px;
  transition: 1s color;
}
.nav-item .dy-nav-link:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0px;
  background-color: transparent;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
}
.nav-item:hover .dy-nav-link:after {
  text-decoration: none !important;
  width: 100%;
  background-color: #ff4484;
}
.nav-item:hover .dy-nav-link {
  text-decoration: none !important;
  color: #ff4484 !important;
}
.dy-btn:hover {
  -webkit-animation: blink-2 0.9s linear;
  animation: blink-2 0.9s linear;
  text-decoration: none !important;
}
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.active {
  color: #ff4484 !important;
}
</style>
